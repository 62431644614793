/* prosa.com/dda layout  */
/* You may adapt certain elements of this CSS in your own projects, but the unique combination of images, colors, sizes, typography, and positioning ("the DESIGN") is copyright 2007 Prosa S.r.l. Italy-VAT:03344200260, and may not be reproduced. */
/* Last updated September 10, 2008 */
/* WARNING: this CSS has not been tested on IE 8 */


/*  Impostazioni base */

* {
    margin: 0;
    padding: 0;
}

html {
    min-width: 788px;
}

body {
    background: #1a0404 url(./assets/sfondoBody.jpg) 0 228px repeat-x;
    font: small/18px "Lucida Sans", "Trebuchet MS", "Bitstream Vera Sans", Verdana, Helvetica, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #cec8c8; /*#e5e6ff;*/
}

a:link, a:visited {
    color: white;
    text-decoration: none;
    border-bottom: 1px dotted #633535;
}

* a:hover {
    color: #bc9696;
}

h1, h2, h3 {
    font-weight: normal;
    color: white;
    font-style: italic;
}

h1 {
    margin-bottom: 11px;
    padding: 0px 0 20px 0;
    font-size: 28px;
    line-height: 30px;
}

h2 {
    padding: 10px 0 10px 0;
    font-size: 20px;
    line-height: 20px;
}

h3 {
    padding: 26px 0 18px 0;
    font-size: 14px;
    line-height: 15px;
    font-weight: normal;
}

h1 a:link, h2 a:link, h3 a:link, h1 a:visited, h2 a:visited, h3 a:visited {
    font-weight: normal;
    color: white;
}

h3 a:link, h3 a:visited {
    text-decoration: none;
}

ol {
    margin: 10px 30px 18px 40px;
}

ul {
    margin: 10px 30px 18px 40px;
}

li {
    margin: 0 0 5px 0;
}

blockquote {
    background: black url(./assets/articleHPanelGradient.jpg) bottom left repeat-x;
    border-top: 1px solid #242424;
    border-bottom: 1px solid black;
    margin: 15px 15px 15px 15px;
    padding: 10px 10px;
    font-weight: bold;
    font-style: italic;
    color: #d1d3ff;
}

address {
    margin: 10px 25px 8px 25px;
    font-weight: bold;
}


/*  Principali elementi di layout */

#fasciamenu {
    background-color: white;
    text-align: center;
    margin: 0 auto;
    width: 100%;
}

#menu {
    text-align: right;
    margin: 0 auto;
    width: 788px;
    height: 82px;
}

#contenitoreSottomenu {
    width: 368px;
    height: auto;
    left: 52%;
    position: absolute;
}

.sottomenu {
    width: 368px;
    height: 25px;
    position: relative;
    padding-top: 2px;
    text-align: center;
    background-color: black;
    z-index: 2;
    filter: alpha(opacity=40);
    -moz-opacity: 40%;
    opacity: 0.4;
    min-height: 25px;
}

#intestazione {
    background: #83161c url(./assets/sfondoInt.jpg) top left repeat-y;
    margin: 0 auto;
    height: 151px;
    text-align: center;
    width: 100%;
}

html > body #intestazione {
    height: auto;
    min-height: 151px;
}

#banner {
    background: transparent url(./assets/banner.jpg) top left no-repeat;
    margin: 0 auto;
    padding: 55px 85px 0 0;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: white;
    text-align: right;
    height: 151px;
    width: 788px;
    width /**/: /**/ 703px;
    height /**/: /**/ 96px;
}

html > body #banner {
    height: auto;
    min-height: 96px;
}

#banner h1 {
    font-size: 16px;
    line-height: 18px;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-style: normal;
}

#pagina {
    background-color: transparent;
    margin: 0 auto;
    width: 788px;
    text-align: left;
}

#contenuto {
    float: left;
    background: #080201 url(./assets/sfondoPrincipale.jpg) top left repeat-x;
    width: 788px;
    height: 500px;
}

html > body #contenuto {
    height: auto;
    min-height: 500px;
}

#colonnaSinistra {
    float: left;
    padding: 0 34px 0px 24px;
    width: 518px;
    width /**/: /**/ 460px;
}

#colonnaDestra {
    float: right;
    padding: 34px 0 0 0;
    width: 270px;
    width /**/: /**/ 270px;
}

#monoColonna {
    float: left;
    padding: 0 24px 24px 24px;
    width: 788px;
    width /**/: /**/ 740px;
}

#piedipagina {
    clear: both;
    background: #080201 url(./assets/ombraSotto.jpg) bottom left no-repeat;
    text-align: center;
    padding: 20px 110px 15px 110px;
    margin: 0 0 20px 0;
    height: 60px;
    font-size: 11px;
    color: #959595;
    width: 788px;
    width /**/: /**/ 568px;
}

#piedipagina p {
    border-top: 1px solid #474747;
}


/*  Microcomponenti di layout */

#promo {
    background: transparent url(./assets/ddaPromo.jpg) 40px 0px no-repeat;
    text-align: right;
    float: left;
    padding: 300px 20px 10px 30px;
    width: 270px;
    width /**/: /**/ 220px;
    height: 18px;
    color: #9e8285;
}

html > body #promo {
    height: auto;
    min-height: 18px;
}

#promo h1 {
    margin-bottom: 0px;
    padding: 0px 0 8px 0;
    font-size: 16px;
    line-height: 16px;
    color: #9d2d37;
}

#promo h2 {
    padding: 0 0 6px 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
}

#panelDomanda {
    background: #0a0200 url(./assets/articleHPanelGradient.jpg) bottom left repeat-x;
    border-top: 1px solid #242424;
    border-bottom: 1px solid black;
    margin: 15px 0 15px 0;
    padding: 6px 15px 10px 15px;
    width: 740px;
    width /**/: /**/ 710px;
    overflow: auto;
}

#panelDomanda p.domanda {
    padding: 10px 0 0 0;
}

#panelDomanda p.domanda, #panelDomanda ol, #panelDomanda ul {
    font-size: 16px;
    font-style: italic;
}

#panelDomanda textarea {
    margin: 10px 0;
}

.captcha {
    max-width: 180px;
    float: right;
    margin: 0 3px;

}

#pannellini {
    float: left;
    clear: left;
    margin: 15px 0 10px 0;
    padding: 0px 0px 0px 4px;
}

html > body #pannellini {
    padding: 0px 0px 0px 12px;
}

.pannellino {
    float: left;
    clear: right;
    background: black url(./assets/sfondoPannellino.png) top left repeat-x;
    border: 1px solid #404040;
    font-size: 12px;
    margin: 8px 8px;
    padding: 6px 10px 10px 10px;
    height: 283px;
    width: 237px;
    width /**/: /**/ 217px;
}

html > body .pannellino {
    height: auto;
    min-height: 283px;
}

#colonnaDestra .pannellino {
    margin: 6px 7px 0px 5px;
    clear: left;
}

html > body #colonnaDestra .pannellino {
    margin: 6px 2px 0px 10px;
}

.pannellino h1 {
    margin-bottom: 0px;
    padding: 0 0 6px 0;
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
}

.pannellino img {
    width: 225px;
    margin: 0 -5px 9px -4px;
}

html > body .pannellino img {
    margin: 0 0 9px -4px;
}

.cappello {
    margin: 0 0 20px 0;
}

.panelOrizzontale {
    background: black url(./assets/articleHPanelGradient.jpg) bottom left repeat-x;
    border-top: 1px solid #242424;
    border-bottom: 1px solid black;
    margin: 0 0 15px 0;
    padding: 6px 10px 7px 15px;
    width: 740px;
    width /**/: /**/ 715px;
    overflow: hidden;
    height: 156px;
}

html > body .panelOrizzontale {
    height: auto;
    min-height: 156px;
}

.panelOrizzontale h1 {
    margin-bottom: 0px;
    padding: 4px 0 6px 0;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
}

.panelOrizzontale h3 {
    padding: 73px 0 6px 0;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    text-align: right;
}

.panelOrizzontale img {
    float: left;
    width: 225px;
    margin: 0 19px 0px -9px;
}

.panelAbstract, .panelRicerca {
    float: left;
    clear: right;
    font-size: 14px;
    margin: 0;
    padding: 0 10px 0 0;
    width: 300px;
    width /**/: /**/ 290px;
    overflow: visible;
}

.panelAbstract h2 {
    padding-top: 0;
    font-size: 14px;
    font-style: italic;
    margin: -4px 0 10px 0;
}

.persona h2 {
    padding-top: 0;
    font-size: 16px;
    font-style: italic;
    margin: -30px 0 20px 0;
}

.evento h2 {
    margin: 10px 0 2px 0;
    line-height: 12px;
    border-bottom: 1px solid #404040;
}

.panelRicerca {
    padding: 0px 25px 0 0;
    height: auto;
    min-height: 0px;
    width: 265px;
    width /**/: /**/ 240px;
}

div.riquadroFiguraFlottante, div.riquadroFiguraFissa {
    text-align: center;
    margin: 5px 5px 5px 0px;
    padding: 0 0px 0 0;
}

div.riquadroFiguraFlottante {
    float: right;
    clear: right;
    background-color: #0a0200;
    text-align: left;
    display: inline;
}

html > body div.riquadroFiguraFlottante {
    margin: 10px 0px 10px 15px;
    padding: 0 20px 0 0;
}

html > body div.riquadroFiguraFissa {
    margin: 5px 8px 5px 0px;
    padding: 0 20px 0 0;
}

img.figuraFlottante {
    margin: 10px 10px 5px 10px;
}

html > body img.figuraFlottante {
    margin: 10px 10px 10px 10px;
}

#panelDomanda div.riquadroFiguraFlottante {
    margin: 10px 5px 10px 15px;
}

img.figuraFissa {
    margin: 10px 5px;
    max-width: 730px;
}

div.didascalia {
    font-style: italic;
    font-size: 12px;
    padding: 5px 5px 10px 10px;
}

#contenuto fieldset {
    clear: both;
    display: block;
    margin: 20px 0 20px 0;
    padding: 8px 5px;
    background: #0a0200 url(./assets/articleHPanelGradient.jpg) bottom left repeat-x;
    border: none;
    border-top: 1px solid #341c1c;
    border-bottom: 1px solid #0a0201;
    text-align: left;
    width: 450px;
}

#contenuto legend {
    margin-left: -3px;
    color: #A4A486;
}

#contenuto fieldset ol {
    padding: 5px 0;
    margin: 0;
    list-style: none;
    text-align: left;
}

#contenuto fieldset label {
    font-style: italic;
    float: left;
    width: 11em;
    margin-right: 1em;
}

#contenuto fieldset li {
    padding-bottom: 5px;
    float: left;
    clear: left;
    width: 100%;
    border-bottom: 1px solid #241414;
    margin: 0;
    margin-bottom: 3px;
}

#ricerca fieldset {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    width: auto;
}

#ricerca input, #ricerca select {
    color: #272727;
    background-color: #ececec;
    border: 1px solid #afafaf;
    font-size: 11px;
    margin-right: 1px;
    padding-left: 1px;
}

.clear {
    clear: both;
}

.opzioni {
    padding: 15px 0px 15px 10px;
}

.opzioni p {
    margin: -6px 0 0 45px;
    width: auto;
    font-style: italic;
}

.opzioni a:link, .opzioni a:visited {
    color: white;
    border-bottom: none;
    display: block;
    margin: 8px 0 0 0;
    padding: 5px 0 0 35px;
    text-decoration: none;
    font-weight: bold;
    background: transparent url(./assets/bottoneTondo.gif) -27px 0 no-repeat;
    height: 27px;
}

html > body .opzioni a:link, html > body .opzioni a:visited {
    height: auto;
    min-height: 27px;
}

.opzioni a:hover {
    color: #d6afaa;
    background: transparent url(./assets/bottoneTondo.gif) 0 -27px no-repeat;
}

#panelDomanda .opzioni a:link, #panelDomanda .opzioni a:visited {
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    color: #cec8c8;
    background: transparent url(./assets/bottoneDomanda.gif) -27px 0 no-repeat;
}

#panelDomanda .opzioni a:hover {
    color: #d6afaa;
    background: transparent url(./assets/bottoneDomanda.gif) 0 -27px no-repeat;
}

#panelDomanda .opzioni a.scelta:link, #panelDomanda .opzioni a.scelta:visited {
    background: transparent url(./assets/bottoneRisposta.gif) -27px 0 no-repeat;
}

#panelDomanda .opzioni a.scelta:hover {
    background: transparent url(./assets/bottoneRisposta.gif) 0 -27px no-repeat;
}


/*  Link, menu e bottoni */

#menu a:link, #menu a:visited {
    display: inline;
    border-bottom: none;
    font-size: 12px;
    padding: 36px 0px 1px 0px;
    margin: 0;
    float: right;
    color: #b6b6b6;
    text-align: center;
    text-decoration: none;
    background: transparent url(./assets/bottoneMenu.png) 0px 0px no-repeat;
    height: 44px;
    width: 92px;
    width /**/: /**/ 92px;
}

html > body #menu a:link {
    height: auto;
    min-height: 44px;
}

#menu a:hover {
    color: #4e4e4e;
    background: transparent url(./assets/bottoneMenu.png) 0px -82px no-repeat;
}

#menu a:link.goHome, #menu a:visited.goHome {
    background: transparent url(./assets/logoDanfoss.svg) 0px 0px no-repeat;
    position: relative;
    margin-top: 13px;
    right: 178px;
    width: 246px;
}

.sottomenu a:link, .sottomenu a:visited {
    border-bottom: none;
    height: 20px;
    color: white;
    font-size: 12px;
    margin: 0 10px;
    padding: 0 5px 0 5px;
    text-decoration: none;
}

html > body .sottomenu a:link {
    height: auto;
    min-height: 20px;
}

.sottomenu a:hover {
    background: #7EA8D0;
}

#piedipagina a:link, #piedipagina a:visited {
    border-bottom: none;
    display: block;
    height: 46px;
    width: 120px;
    color: #CCDCCB;
    padding: 0 0 0 52px;
    text-decoration: none;
    background: transparent top left no-repeat;
    /*background: transparent url(logoProsa.gif) top left no-repeat;*/
}

html > body #piedipagina a:link {
    height: auto;
    min-height: 46px;
}

#contenuto .bottone, #contenuto .bottone,
#dialogoModale .bottone, #dialogoModale .bottone,
#contenuto input.bottone {
    cursor: pointer;
    border: none;
    display: inline;
    font-size: 11px;
    padding: 4px 0px 1px 0px;
    margin: 4px 0 0 0;
    float: right;
    color: #c0c0c0;
    text-align: center;
    text-decoration: none;
    background: transparent url(./assets/bottone.gif) 0px 0px no-repeat;
    height: 23px;
    width: 98px;
    min-height: 23px;
    width /**/: /**/ 98px;
    overflow: hidden;
}

#contenuto .bottone:hover, #dialogoModale .bottone:hover,
#contenuto input.bottone:hover {
    background-position: 0px -29px;
    color: white;
}

#contenuto input.bottone {
    padding: 0px 0px 0px 0px;
    height: 29px;
    min-height: 29px;
}

#contenuto a:link.bottoneGo, #contenuto a:visited.bottoneGo,
#ricerca input.bottoneGo {
    cursor: pointer;
    border: none;
    display: inline;
    font-size: 5px;
    padding: 0 0;
    margin: 0 0;
    float: right;
    color: #c0c0c0;
    text-align: center;
    text-decoration: none;
    background: transparent url(./assets/bottoneSmall.gif) 0px 0px no-repeat;
    height: 18px;
    width: 25px;
    min-height: 18px;
    width /**/: /**/ 25px;
}

#contenuto a:hover.bottoneGo,
#ricerca input.bottoneGo:hover {
    background-position: 0px -18px;
    color: white;
}

#contenuto .bottoneBack:link, #contenuto .bottoneBack:visited {
    background: #180a09 url(./assets/frecciaBk.gif) 0 -18px no-repeat;
    color: #c0c0c0;
    border-top: 1px solid #341c1c;
    border-bottom: 1px solid #0a0200;
    padding: 1px 3px 2px 25px;
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 40px;
}

#contenuto a.bottoneBack:hover {
    color: white;
    background-position: -18px 0;
}


#colonnaSinistra h6, #monoColonna h6 {
    font-size: 11px;
    line-height: 29px;
    background: transparent url(./assets/baseBottoneLinL.gif) left top no-repeat;
    margin: 15px 0px 0px;
    padding: 0px 0px 0px 11px;
    font-weight: normal;
    float: right;
}

#colonnaSinistra h6 a, #monoColonna h6 a {
    background: transparent url(./assets/baseBottoneBack.gif) right 0px no-repeat;
    margin: 0px;
    text-align: left;
    text-decoration: none;
    border-bottom: none;
    font-weight: normal;
    display: block;
    min-height: 23px;
    padding: 0 50px 0 3px;
    color: #c0c0c0;
}

#colonnaSinistra h6 a:hover, #monoColonna h6 a:hover {
    background: transparent url(./assets/baseBottoneBack.gif) right -29px no-repeat;
    color: white;
}


/*  Microcomponenti di layout aggiunti dinamicamente */

#dialogoModale {
    display: none;
}

#velo {
    width: 0;
    position: absolute;
    background-color: #362f24;
    z-index: 3;
    filter: alpha(opacity=50);
    -moz-opacity: 50%;
    opacity: 0.5;
}

#finestraDialogo {
    width: 320px;
    height: 150px;
    position: absolute;
    float: left;
    background-color: #1a0404;
    border: 1px solid #404040;
    padding: 4px 4px;
    z-index: 50;
    text-align: left;
}

html > body #finestraDialogo {
    max-width: 320px;
    height: auto;
    width: auto;
    position: fixed;
    min-width: 320px;
    min-height: 150px;
}

#titoloDialogo {
    background: #808080 url(./assets/dialogoBgGradient.gif) top left repeat-x;
    padding: 3px 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    height: 45px;
    color: #f5f9f9;
}

html > body #titoloDialogo {
    min-width: 310px;
}

#testoDialogo {
    position: relative;
    display: block;
    float: none;
    background: #808080 url(./assets/alertIcon.gif) 20px 10px no-repeat;
    padding: 10px 20px 20px 100px;
    text-align: left;
    font-size: 13px;
    color: white;
    height: 60px;
    width: 200px;
}

html > body #testoDialogo {
    height: auto;
    min-height: 60px;
}

#barraProgresso {
    display: none;
    top: 0px;
    width: 240px;
    height: 40px;
    padding: 42px 0px 0px 0px;
    position: absolute;
    background: #4b1114 url(./assets/progressBar.gif) 20px 20px no-repeat;
    border: 1px solid white;
    z-index: 30;
    text-align: center;
}

html > body #barraProgresso {
    position: fixed;
}


/* Definition List */

dl {
    display: block;
    background: #0a0200 url(./assets/articleHPanelGradient.jpg) bottom left repeat-x;
    width: 740px;
    overflow: hidden;
    border-bottom: 2px solid #0a0201;
    margin: 0 0 2px 0;
}

dt {
    clear: both;
    display: block;
    float: left;
    padding: 0px 5px;
    width: 130px;
    margin-right: 0px;
    font-size: 13px;
    text-align: left;
    color: #d6afaa;
    border-top: 1px solid #241414;
    background: transparent;
    font-style: italic;
}

dd {
    display: block;
    color: #b0a6a6;
    float: left;
    font-size: 13px;
    width: 590px;
    padding: 0px 5px;
    border-top: 1px solid #241414;
    margin: 0 0;
}

dd.id {
    font-weight: bold;
    color: white;
}

dd a:link, dd a:visited {
    font-weight: bold;
    color: #b0a6a6;
}

dd a:hover {
    color: #bc9696;
}
